.pop-box {
  display: none;
  position: fixed;
  top: 20%;
  left: 45%;
  // transform: translate(-50%,-25%);
  height: auto;
  max-width: 18.75rem;
  padding: 15px 20px;
  // background-color: rgba(180, 51, 41, 0.16);
  background-color: rgba(68, 113, 209,.8);
  // box-shadow: 0 2px 4px 0 rgba(105, 108, 255, 0.4);
  animation-delay: calc(-1s * var(--i));
  // box-shadow: 0 1.5625rem 2.8125rem rgba(105, 108, 255, 0.4);
  // border: 0.0625rem solid rgba(105, 108, 255, 0.4);
  // border-right: 0.0625rem solid rgba(105, 108, 255, 0.4);
  border-bottom: 0.0625rem solid rgba(243, 241, 241, 0.4);
  z-index: 20;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(68, 113, 209, 0.4);
  // color: rgb(15, 15, 15);
  color: #fff;
  font-weight: 500;
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 25px;
  }
}

.pop-show {
  display: block !important;
}

@keyframes animate1 {
  0% {
    transform: translateY(-6.25rem);
  }
  50% {
    transform: translateY(65px);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(65px);
  }
}

@media screen and (max-width: 700px) {
  .pop-box {
    left: 20%;
  }
}
