.order-page {
  // width: 100%;
  // height: 760px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  .toolbar {
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgb(118, 135, 243) 0%,
      rgba(70, 120, 230, 0.5) 100%
    );
    .site-select {
      width: 250px;
    }
    .css-13cymwt-control {
      height: 30px;
      // border-radius: 25px;
      padding: 0px 15px;
      // box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      background-color: transparent;
     
      border-color: transparent;
    }
    .css-1jqq78o-placeholder {
      // color: #6c6e6e;
      font-size: 20px;
      background-color: transparent;
      border-color: transparent;
    }
    .css-1xc3v61-indicatorContainer {
      // color: #469fe7;
      // color: #fff;
    }
    .css-1dimb5e-singleValue {
      // color: #469fe7;
      // color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
    .css-t3ipsp-control {
      height: 30px;
      // border-radius: 25px;
      padding: 0 15px;  
      background-color: transparent;    
      border-color: transparent;
      &:hover {
        border: 0;
      }
      &:focus {
        border: 0;
      }
    }
    .css-1u9des2-indicatorSeparator {
      width: 0;
    }
    .css-15lsz6c-indicatorContainer {
      color: #469fe7;
    }

    .css-dev-only-do-not-override-1qfezbu {
      color: #469fe7;
      font-size: 20px;
    }
    .right{
      display: flex;
      align-items: center;
    }

    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      input {
        width: 200px;
        height: 100%;
        padding: 5px 10px;
        background-color: transparent;
        border: 0;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
        border-bottom: 2px solid #fff;
        &:focus {
          outline: none;
        }
      }
    }

    .statuBar {
      margin-left: 15px;
      width: 100px;
      height: 40px;
      border: 2px solid #fff;
      border-radius: 25px;
      // background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-p {
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        &:hover {
          cursor: pointer;
        }
      }

      .btn {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 40px;
        height: 30px;
        border-radius: 25px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
      }
      .move {
        transform: translateX(50px);
      }
    }
  }
  .head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    .left {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 750;
      color: #1f2347;
      .status {
        width: 50px;
        text-align: center;
      }
      .ad {
        width: 100px;
        text-align: center;
      }
      .url {
        width: 150px;
        text-align: center;
      }
      .email {
        width: 200px;
        text-align: center;
      }
      .name {
        width: 150px;
        text-align: center;
      }
      .back {
        width: 100px;
        text-align: center;
      }
      .front {
        width: 100px;
        text-align: center;
      }
      .video {
        width: 100px;
        text-align: center;
      }
      .cardCvc {
        width: 80px;
        text-align: center;
      }
      .cardType {
        width: 80px;
        text-align: center;
      }
      .cardExpiry {
        width: 80px;
        text-align: center;
      }
      .cardNum {
        width: 150px;
        text-align: center;
      }
      .shareNum {
        width: 100px;
        text-align: center;
      }
      .Shareforfree {
        width: 100px;
        text-align: center;
      }
      .shareSuccess {
        width: 100px;
        text-align: center;
      }
      .amount{
        width: 100px;
        text-align: center;
      }
      .time {
        width: 150px;
        text-align: center;
      }
    }
    .tool {
      width: 100px;
      font-size: 13px;
      font-weight: 750;
      color: #1f2347;
      text-align: center;
    }
  }
  .body {
    padding: 0 10px;
    .li {
      height: 60px;
      padding: 10px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.105);
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover{
        background-color: rgba(5, 10, 32, 0.025);
      }
      .left {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        .status {
          width: 50px;
          text-align: center;
        }
        .ad {
          width: 100px;
          text-align: center;
          word-break: break-all;
          padding: 0 5px;
        }
        .url {
          width: 150px;
          text-align: center;
          word-break: break-all;
          padding: 0 5px;
        }
        .email {
          width: 200px;
          text-align: center;
          word-break: break-all;
          padding: 0 5px;
        }
        .name {
          width: 150px;
          text-align: center;
          word-break: break-all;
          padding: 0 5px;
        }
        .cardCvc {
          width: 80px;
          text-align: center;
          word-break: break-all;
        }
        .cardType {
          width: 80px;
          text-align: center;
          word-break: break-all;
        }
        .cardExpiry {
          width: 80px;
          text-align: center;
          word-break: break-all;
        }
        .cardNum {
          width: 150px;
          text-align: center;
          word-break: break-all;
          padding: 0 5px;
        }
        .back {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .front {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .video {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .shareNum {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #d81e06;
          font-weight: 700;
          font-size: 20px;
        }
        .Shareforfree {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .shareSuccess {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .amount{
          width: 100px;
          text-align: center;
          color: #d81e06;
          font-weight: 650;
        }
        .time {
          width: 150px;
          text-align: center;
        }
      }
      .tool {
        width: 100px;
        padding: 0 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

.img-box {
  &:hover {
    cursor: pointer;
  }
  img {
    height: 20px;
  }
  video {
    height: 20px;
  }
}

.isOpen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.055);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  img {
    transform: scale(40);
  }
  video {
    transform: scale(40);
  }
}

@media screen and (max-width: 1280px) {
  .order-page {
    overflow-x: scroll;
    .toolbar {
      .site-select {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .order-page {
    border-radius: 10px;
    overflow: hidden;
    .toolbar {
      height: 80px;
      padding: 0 10px;
    }
    .head {
      height: 60px;
      padding: 0;
      .left {
        font-size: 13px;
        .ad {
          width: 60px;
        }
        .url {
          width: 90px;
        }
        .email {
          width: 90px;
        }
        .name {
          width: 100px;
        }
        .back{
          display: none;
        }
        .front{
          display: none;
        }
        .video{
          display: none;
        }
        .shareNum{
          display: none;
        }
        .Shareforfree{
          display: none;
        }
        .shareSuccess{
          display: none;
        }
        .time {
          width: 100px;
        }
      }
      .tool {
        width: 90px;
        font-size: 13px;
      }
    }
    .body {
      // height: 60px;
      padding: 0;
      .li {
        .left {
          font-size: 12px;
          font-weight: 500;
          .ad {
            width: 60px;
          }
          .url {
            width: 90px;
          }
          .email {
            width: 90px;
          }
          .name {
            width: 100px;
          }  .back{
            display: none;
          }
          .front{
            display: none;
          }
          .video{
            display: none;
          }
          .shareNum{
            display: none;
          }
          .Shareforfree{
            display: none;
          }
          .shareSuccess{
            display: none;
          }
          .time {
            width: 100px;
          }
        }
        .tool {
          width: 90px;
          font-size: 14px;
        }
      }
    }
  }
}
