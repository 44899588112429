.body-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f9;
  overflow: hidden;
  .left-bar {
    width: 15.625rem;
    height: 100%;
    transition: all 0.3s;
  }
  .right-bar {
    width: calc(100% - 15.625rem);
    height: 100%;
    transition: all 0.3s;
    header {
      height: 90px;
      width: 100%;
      .head-bar {
        width: 100%;
        height: 100%;
        padding: 25px 1.875rem 0 15px;
        .nav-header {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .header-left {
            display: flex;
            align-items: center;
            h1 {
              font-size: 2.375rem;
              color: #000;
              font-weight: 400;
              line-height: 6.25rem;
            }
          }
          .header-right {
            display: flex;
            align-items: center;
            .action-bar {
              display: flex;
              align-items: center;
              li {
                padding: 0 0 0 1rem;
                display: flex;
                align-items: center;
                a {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 3.625rem;
                  height: 3.625rem;
                }
              }
            }
            .generate {
              width: 12.5rem;
              height: 3.5rem;
              border-radius: 1.125rem;
              margin: 0 0 0 1rem;
              border: 0.0625rem solid #5bcfc5;
              background-color: #5bcfc5;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 1.25rem;

              color: #fff;
            }
            .profile {
              // width: 8.125rem;
              // height: 70px;
              // margin: 0 0 20px;
              border: 0.0625rem solid #f5f5f5;
              border-radius: 1rem;
              // padding: 0.625rem 0.9375rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .left {
                display: flex;
                align-items: center;
                .img-box {
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                  p {
                    color: #1362fc;
                    font-size: 18px;
                    // font-size: .8125rem;
                    font-weight: 600;
                    padding: 0 5px;
                  }
                }

                .user-text {
                  // margin-left: 1rem;
                  // height: 3.125rem;
                  padding: 0 0.625rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  p {
                    color: #1362fc;
                    font-size: 20px;
                    font-weight: 600;
                  }
                  h6 {
                    font-size: 20px;
                    color: #1362fc;
                    font-weight: 600;
                    margin: 0 10px;
                  }
                }
              }
              .logout {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .page-contain {
      width: 100%;
      height: calc(100% - 6.25rem);
      padding: 0.9375rem;
      overflow-y: scroll;
    }
  }
  .reduce-left {
    width: 100px !important;
    .leftBar {
      .left-box {
        padding: 20px 10px !important;
        .head-title {
          justify-content: center;
          p {
            display: none !important;
          }
          .icon {
            transition: all 0.3s;
            &:hover {
              cursor: pointer;
              transform: scale(1.5, 1.5);
            }
          }
        }
        .tab-list {
          padding: 0 7px !important;
          li {
            padding: 10px 0 !important;
            justify-content: center;
            p {
              display: none !important;
            }
            .icon {
              transition: all 0.3s;
              &:hover {
                cursor: pointer;
                transform: scale(1.25, 1.25);
              }
            }
          }
        }
      }
    }
  }

  .reduce-right {
    width: calc(100% - 6.25rem) !important;
  }
}

@media screen and (max-width: 1000px) {
  .body-container {
    .reduce-left {
      padding: 5px !important;
      width: 65px !important;
      .leftBar {
        padding: 0 !important;
        .left-box {
          padding: 10px 5px !important;
          border-radius: 10px !important;
          .head-title {
            img {
              width: 35px !important;
            }
          }
          .tab-list {
            padding: 0 3px !important;
            li {
              padding: 10px 0 !important;
              justify-content: center;
              p {
                display: none !important;
              }
              .icon {
                transition: all 0.3s;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.05, 1.05);
                }
              }
            }
          }
        }
      }
    }
    .right-bar {
      header {
        height: 60px;
        .head-bar{
          padding-top: 20px;
        }
      }
      .page-contain {
        height: calc(100% - 60px);
      }
    }
    .reduce-right {
      width: calc(100% - 65px) !important;
    }
  }
}
