.user-page {
  width: 100%;
  // height: 760px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  .toolbar {
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    background: linear-gradient(
      180deg,
      rgb(118, 135, 243) 0%,
      rgba(70, 120, 230, 0.5) 100%
    );
  }
  .head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      .name {
        width: 200px;
        text-align: center;
      }
      .xing {
        width: 200px;
        text-align: center;
      }
      .password {
        width: 200px;
        text-align: center;
      }
      .nickname {
        width: 200px;
        text-align: center;
      }
      .time {
        width: 300px;
        text-align: center;
      }
    }
    .tool {
      width: 150px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      text-align: center;
    }
  }
  .body {
    padding: 0 20px;
    .li {
      height: 60px;
      border-top: 1px solid rgba(0, 0, 0, 0.105);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #697a8d;
        .name {
          width: 200px;
          text-align: center;
        }
        .xing {
          width: 200px;
          text-align: center;
        }
        .password {
          width: 200px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .nickname {
          width: 200px;
          text-align: center;
        }
        .time {
          width: 300px;
          text-align: center;
        }
      }
      .tool {
        width: 150px;
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .user-page {
    overflow-x: scroll;
    .head {
      .left {
        font-size: 16px;
        .name {
          width: 100px;
        }
        .xing {
          width: 150px;
        }
        .password {
          width: 150px;
        }
        .nickname {
          width: 150px;
        }
        .time {
          width: 150px;
        }
      }
      .tool {
        font-size: 16px;
      }
    }
    .body {
      .li {
        .left {
          font-size: 16px;
          .name {
            width: 100px;
          }
          .xing {
            width: 150px;
          }
          .password {
            width: 150px;
          }
          .nickname {
            width: 150px;
          }
          .time {
            width: 150px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .user-page {
    border-radius: 10px;
    .toolbar {
      height: 80px;
      padding: 0 10px;
    }
    .head {
      height: 60px;
      padding: 0;
      .left {
        font-size: 13px;
        .name {
          width: 90px;
        }
        .xing {
          width: 90px;
        }
        .password {
          width: 90px;
        }
        .nickname {
          width: 90px;
        }
        .time {
          width: 100px;
        }
      }
      .tool {
        width: 100px;
        font-size: 13px;
      }
    }
    .body {
      padding: 0;
      height: auto;
      .li {
        .left {
          font-size: 13px;
          .name {
            width: 90px;
          }
          .xing {
            width: 90px;
          }
          .password {
            width: 90px;
          }
          .nickname {
            width: 90px;
          }
          .time {
            width: 100px;
          }
        }
        .tool {
          width: 100px;
          font-size: 14x;
        }
      }
    }
  }
}
