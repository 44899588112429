.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to right bottom,
    #9a9a9a,
    #848484,
    #6f6f6f,
    #5a5a5a,
    #464646,
    #3b3b3b,
    #303030,
    #252525,
    #1f1f1f,
    #191919,
    #141414,
    #0c0c0c
  );
  background: #646464;
  position: relative;
  overflow: hidden;
  .img-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(../image/bg.png) no-repeat;
    background-size: contain;
    animation: 2s scaleImg linear infinite forwards alternate;
  }
  .login-box {
    width: 488px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #fff;
    z-index: 3;
    border-radius: 5px;
    padding: 64px 48px;
    h1 {
      font-size: 32px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 40px;
        transform: scaleX(1);
        height: 5px;
        top: -5px;
        left: 0;
        background-color: #0e1776;
        border-radius: 5px;
      }
    }
    .input-row {
      margin: 30px 0;
      input {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: block;
        padding: 15px 15px;
        padding-left: 25px;
        margin: 26px 0;
        border: none;
        border-left: 4px solid #0e1776;
        border-radius: 4px;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
    }
    .code-row {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: block;
        padding: 15px 15px;
        padding-left: 25px;
        margin: 26px 0;
        border: none;
        border-left: 4px solid #0e1776;
        border-radius: 4px;
        font-weight: 500;
        &:focus {
          outline: none;
        }
      }
      .btn {
        width: 30%;
        padding: 15px;
        border-radius: 4px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0e1776;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
    .btn-row {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        width: 100%;
        padding: 15px;
        border-radius: 4px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0e1776;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
}

@keyframes scaleImg {
  0% {
    background-size: 80%;
  }

  100% {
    background-size: 84%;
  }
}
