.addUser-page {
  width: 100%;
  // height: 100%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  padding: 100px 30px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .row {
    width: 800px;
    margin: 20px 0;
    .title {
      margin: 0 0 20px;
      font-size: 20px;
      font-weight: 600;
      color: rgb(102, 132, 230);
    }
    input {
      width: 100%;
      border: 1px solid #bbb;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
      font-size: 18px;
      font-weight: 600;
      color: #697a8d;
      &:focus {
        border: 2px solid rgb(69, 103, 216);
        outline: none;
      }
    }
  }
  .footer{
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }
}
.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

@media screen and (max-width:1000px) {
  .addUser-page{
    .row{
      width: 100%;
    }
  }
}