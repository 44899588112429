.cost-page {
  width: 100%;
  // height: 760px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  .toolbar {
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgb(118, 135, 243) 0%,
      rgba(70, 120, 230, 0.5) 100%
    );
    .site-select {
      width: 250px;
    }
    .right{
      display: flex;
      align-items: center;
      .search {
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        input {
          width: 150px;
          height: 100%;
          padding: 5px 10px;
          background-color: transparent;
          border: 0;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
          border-bottom: 2px solid #fff;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .css-13cymwt-control {
      height: 30px;
      // border-radius: 10px;
      padding: 0px 15px;
      // box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      background-color: transparent;
      border-color: transparent;
    }
    .css-1jqq78o-placeholder {
      color: #6c6e6e;
      font-size: 20px;
    }
    .css-1xc3v61-indicatorContainer {
      // color: #469fe7;
    }
    .css-1dimb5e-singleValue {
      // color: #469fe7;
      font-size: 16px;
      font-weight: 500;
    }
    .css-t3ipsp-control {
      height: 30px;
      // border-radius: 10px;
      padding: 0 15px;
      // box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      background-color: transparent;    
      border-color: transparent;
      &:hover {
        border: 0;
      }
      &:focus {
        border: 0;
      }
    }
    .css-1u9des2-indicatorSeparator {
      width: 0;
    }
    .css-15lsz6c-indicatorContainer {
      color: #469fe7;
    }

    .css-dev-only-do-not-override-1qfezbu {
      color: #469fe7;
      font-size: 20px;
    }
    
  }
  .head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .left {
      display: flex;
      align-items: center;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      .site {
        width: 200px;
        text-align: center;
      }
      .category {
        width: 200px;
        text-align: center;
      }
      .xing {
        width: 200px;
        text-align: center;
      }
      .price {
        width: 200px;
        text-align: center;
      }
      .proof {
        width: 200px;
        text-align: center;
      }
      .time {
        width: 300px;
        text-align: center;
      }
    }
    .tool {
      width: 150px;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      text-align: center;
    }
  }
  .body {
    padding: 0 20px;
    .li {
      height: 60px;
      border-top: 1px solid rgba(0, 0, 0, 0.105);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #697a8d;
        .site {
          width: 200px;
          text-align: center;
        }
        .category {
          width: 200px;
          text-align: center;
        }
        .xing {
          width: 200px;
          text-align: center;
        }
        .price {
          width: 200px;
          text-align: center;
          color: rgba(255, 0, 0, 0.5);
          font-weight: 700;
        }
        .proof {
          width: 200px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          height: 100px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .time {
          width: 300px;
          text-align: center;
        }
      }
      .tool {
        width: 150px;
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .cost-page {
    .toolbar{
      .site-select{
        width: 250px;
      }
    }
    .head {
      .left {
        font-size: 16px;
        .site {
          width: 200px;
        }
        .xing {
          width: 150px;
        }
        .price {
          width: 100px;
        }

        .time {
          width: 150px;
        }
      }
      .tool {
        font-size: 16px;
      }
    }
    .body {
      .li {
        .left {
          font-size: 15px;
          .site {
            width: 200px;
          }
          .xing {
            width: 150px;
          }
          .price {
            width: 100px;
          }

          .time {
            width: 150px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .cost-page {
    border-radius: 10px;
    .toolbar {
      height: 80px;
      padding: 0 10px;
    }
    .head {
      height: 60px;
      padding: 0;
      .left {
        font-size: 13px;
        .site {
          width: 100px;
        }
        .xing {
          width: 90px;
        }
        .price {
          width: 90px;
        }
        .time {
          width: 100px;
        }
      }
      .tool {
        width: 100px;
        font-size: 13px;
      }
    }
    .body {
      padding: 0;
      .li {
        height: 60px;
        .left {
          font-size: 13px;
          .site {
            width: 100px;
          }
          .xing {
            width: 90px;
          }
          .price {
            width: 90px;
          }
          .time {
            width: 100px;
          }
        }
        .tool {
          width: 100px;
          font-size: 14x;
        }
      }
    }
  }
}
