.home-page {
  width: 100%;
  height: 100%;
  .card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0 20px;
    .card {
      width: 22%;
      height: 150px;
      border-radius: 25px;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      padding: 30px;
      &:nth-child(1) {
        background: linear-gradient(45deg, #59bcff 21%, #59d7ff 100%);
      }
      &:nth-child(2) {
        background: linear-gradient(45deg, #4ad994 0%, #57f998 100%);
      }
      &:nth-child(3) {
        background: linear-gradient(45deg, #fdb765 0%, #ffe485 100%);
      }
      &:nth-child(4) {
        background: linear-gradient(45deg, #ff6d59 0%, #ffd584 100%);
      }
      position: relative;
      .num {
        color: #fff;
        font-size: 36px;
        margin: 0 0 20px;
        font-weight: 600;
      }
      .title {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
      .icon {
        position: absolute;
        top: 50px;
        right: 50px;
        animation: 3s scale ease-in-out infinite;
      }
    }
  }
  .select-row {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    .type {
      width: 15%;
      margin-right: 20px;
    }
    .site-select {
      width: 20%;
      margin-right: 20px;
    }
    .ad-select {
      width: 20%;
    }
    .user-select {
      width: 20%;
      margin-right: 20px;
    }

    .css-13cymwt-control {
      //   width: 30%;
      height: 50px;
      border-radius: 10px;
      padding: 0px 15px;
      //   background: linear-gradient(30deg, #daedfc, #d3f8ff);
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
    }
    .css-1jqq78o-placeholder {
      color: #6c6e6e;
      font-size: 20px;
    }
    .css-1xc3v61-indicatorContainer {
      color: #469fe7;
    }
    .css-1dimb5e-singleValue {
      color: #469fe7;
      font-size: 16px;
      font-weight: 500;
    }
    .css-t3ipsp-control {
      //   width: 30%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      //   background: linear-gradient(30deg, #53acf0, #84dcee);
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      &:hover {
        border: 0;
      }
      &:focus {
        border: 0;
      }
    }
    .css-1u9des2-indicatorSeparator {
      width: 0;
    }
    .css-15lsz6c-indicatorContainer {
      color: #469fe7;
    }

    .datePicker {
      margin-right: 20px;
      width: 20%;
      height: 50px;
      border-radius: 10px;
      padding: 0 15px;
      //   background: linear-gradient(45deg, #c1e8ff 21%, #c6f2ff 100%);
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      background-color: #fff;
      .semi-datepicker-range-input {
        background-color: #fff;
        height: 100%;
        &:hover {
          background-color: #fff;
        }
      }
      .semi-datepicker-range-input {
        background-color: #fff;
      }
      input {
        color: #469fe7;
        font-size: 15px;
        // background-color: #fff;
        &::placeholder {
          color: #6c6e6e;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .chart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;
    .bang-modle {
      width: 30%;
      height: 580px;
      padding: 30px 20px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      font-size: 15px;
      .title-head {
        display: flex;
        justify-content: end;
        align-items: center;
        color: #dbbe19;
        font-size: 24px;
        font-weight: 600;
      }

      .title {
        margin: 30px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #dbbe19;
        font-size: 20px;
        .right {
          width: 350px;
          display: flex;
          justify-content: space-between;
        }
      }
      .bang-content {
        display: flex;
        justify-content: space-between;
        margin: 20px 0 0;
        overflow: scroll;
        height: 420px;
        .li {
          margin: 15px 0;
          display: flex;
          flex-direction: column;
          justify-self: center;
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              font-size: 16px;
              font-weight: 600;
              color: #525252;
              // margin-left: 10px;
            }
            .right {
              .bang-icon {
                margin: 0 10px;
              }
            }
          }
          .bangdan-content {
            display: flex;
            justify-content: space-between;
          }

          .bi {
            display: flex;
            align-items: center;
            margin: 10px 0;
            .name {
              color: #6c6e6e;
              font-weight: 600;
              display: flex;
              align-items: center;
              font-size: 13px;
              margin-right: 1px;
              width: 80px;
            }
            .jiang {
              margin-right: 10px;
            }
            .value {
              color: #424242;
              font-weight: 600;
              line-height: 20px;
              display: flex;
              align-items: center;
              font-size: 15px;
              .je {
                min-width: 70px;
                color: #fdb765;
                margin-right: 10px;
              }
              .sl {
                color: #4ad994;
                min-width: 40px;
                margin-right: 10px;
              }
              .ggf {
                min-width: 80px;
                margin-right: 10px;
                color: #59bcff;
              }
              .bl {
                width: 70px;
                color: #ff6d59;
                margin-right: 10px;
              }
              .dthf {
                color: #8f8e8e;
                width: 100px;
              }
            }
          }
        }
      }
    }
    .pixel-model {
      width: 20%;
      height: 580px;
      padding: 30px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      .title-row {
        display: flex;
        justify-content: end;
      }
      .model-box {
        margin: 20px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: scroll;
        height: 480px;
        .model-row {
          padding: 8px 0;
          margin: 0 0 20px;
          background-color: rgba(150, 150, 150, 0.105);
          .title {
            text-align: center;
            margin: 0 0 5px;
            font-size: 14px;
            font-weight: normal;
            color: #6c6e6e;
            position: relative;
            span {
              position: absolute;
              right: 5px;
              top: 20px;
              color: #27bd72;
              font-size: 12px;
              font-weight: 600;
            }
          }
          .num {
            font-size: 12px;
            text-align: center;
            font-weight: 500;
            color: #ff0505;
          }
        }
      }
    }
    .chart-modle {
      width: 49%;
      height: 580px;
      padding: 20px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .user-model {
      width: 100% !important;
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  90% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 1280px) {
  .home-page {
    .card-row {
      .card {
        width: 24%;
        padding: 20px;
        height: 140px;
        border-radius: 20px;
        .num {
          font-size: 25px;
          margin: 20px 0;
        }
        .title {
          font-size: 16px;
        }
        .icon {
          right: 10px;
        }
      }
    }
    .chart-row {
      flex-wrap: wrap;
      justify-content: space-between;
      .bang-modle {
        padding: 20px;
        width: 48%;
        font-size: 12px;
        margin: 0 0 20px;
        .bang-content {
          .li {
            .bi {
              .value {
                color: #424242;
                font-weight: 600;
                line-height: 20px;
                display: flex;
                align-items: center;
                font-weight: 700;
                .je {
                  width: 100px;
                  color: #fdb765;
                }
                .sl {
                  color: #4ad994;
                  width: 50px;
                }
                .ggf {
                  width: 100px;
                  color: #59bcff;
                }
                .bl {
                  color: #ff6d59;
                }
              }
            }
          }
        }
      }
      .pixel-model {
        width: 48%;
        padding: 20px;

        margin: 0 0 20px;
      }
      .chart-modle {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .home-page {
    .card-row {
      flex-wrap: wrap;
      .card {
        width: 48%;
        margin: 0 0 20px;
        .icon {
          right: 20px;
        }
      }
    }
    .select-row {
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .site-select {
        width: 48%;
        margin: 0 0 20px;
      }
      .datePicker {
        width: 48%;
        margin: 0 0 20px;
      }
      .type {
        width: 48%;
        margin: 0 0 20px;
      }
      .user-select {
        width: 48%;
        margin: 0 0 20px;
      }
      .ad-select {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home-page {
    .card-row {
      .card {
        width: 100%;
      }
    }
    .select-row {
      .site-select {
        width: 100%;
      }
      .datePicker {
        width: 100%;
      }
      .type {
        width: 100%;
      }
      .user-select {
        width: 100%;
      }
    }
    .chart-row {
      .bang-modle {
        width: 100%;
      }
      .pixel-model {
        width: 100%;
      }
    }
  }
}
