.leftBar {
  width: 100%;
  height: 100%;
  padding: 10px;
  .left-box {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
    transition: all 0.2s ease;
    padding: 20px;
    .head-title {
      height: 50px;
      display: flex;
      align-items: center;
      img {
        width: 45px;
      }
      p {
        color: #3f74dd;
        font-size: 22px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    .tab-list {
      li {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 23px 15px;
        border-radius: 10px;
        margin: 15px 0;
        p {
          color: #697a8d;
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
        &:hover {
          background-color: rgba(7, 71, 247, 0.26);
          box-shadow: 0 2px 4px 0 rgba(43, 90, 245, 0.4);
        }
      }
      .active {
        background-color: rgba(7, 71, 247, 0.6);
        box-shadow: 0 2px 4px 0 rgba(34, 74, 250, 0.4);
        p {
          color: #fff;
        }
      }
    }
  }
}
