.addConfig-page {
  width: 100%;
  // height: 100%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  padding: 100px 30px 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .row {
    width: 800px;
    margin: 15px 0;
    display: flex;
    align-items: center;
    .title {
      width: 150px;
      font-size: 20px;
      font-weight: 600;
      color: rgb(102, 132, 230);
    }
    input {
      width: 500px;
      border: 1px solid #bbb;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
      font-size: 18px;
      font-weight: 600;
      color: #697a8d;
      &:focus {
        border: 2px solid rgb(69, 103, 216);
        outline: none;
      }
    }
    .css-13cymwt-control {
      width: 500px;
    }
    .css-t3ipsp-control {
      width: 500px;
    }
    .css-1nmdiq5-menu{
      width: 500px;
    }
    textarea {
      min-height: 200px;
      width: 500px;
      border: 1px solid #bbb;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
      font-size: 18px;
      font-weight: 600;
      color: #697a8d;
      &:focus {
        border: 2px solid rgb(69, 103, 216);
        outline: none;
      }
    }
    .control {
      font-size: 16px;
      font-weight: 600;
      color: #697a8d;
    }
  }
  .footer {
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }
}
.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}


@media screen and (max-width:1000px) {
  .addConfig-page{
    .row{
      width: 100%;
      flex-direction: column;
      .title{
        font-size: 16px;
        margin: 0 0 10px;
      }
      input{
        width: 100%;
      }
    }
  }
}