.site-page {
  width: 100%;
  // height: 760px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  .toolbar {
    height:80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgb(118, 135, 243) 0%,
      rgba(70, 120, 230, 0.5) 100%
    );
    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      input {
        width: 200px;
        height: 100%;
        padding: 5px 10px;
        background-color: transparent;
        border: 0;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
        border-bottom: 2px solid #fff;
        &:focus {
          outline: none;
        }
      }
    }
    .statuBar {
      width: 100px;
      height: 50px;
      border: 2px solid #fff;
      border-radius: 25px;
      // background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-p {
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        &:hover {
          cursor: pointer;
        }
      }

      .btn {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        background-color: #fff;
        transition: all 0.3s ease-in-out;
      }
      .move {
        transform: translateX(50px);
      }
    }
  }
  .head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .left {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      .site {
        width: 200px;
        text-align: center;
      }
      .category {
        width: 200px;
        text-align: center;
      }
      .state {
        width: 100px;
        text-align: center;
      }
      .xing {
        width: 200px;
        text-align: center;
      }
      .time {
        width: 300px;
        text-align: center;
      }
    }
    .tool {
      width: 200px;
      font-size: 16px;
      font-weight: 700;
      color: rgba(39, 82, 221, 0.8);
      text-align: center;
    }
  }
  .body {
    padding: 0 20px;
    .li {
      height: 60px;
      border-top: 1px solid rgba(0, 0, 0, 0.105);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #697a8d;
        .site {
          width: 200px;
          text-align: center;
          word-wrap: break-word;
          padding: 0 5px;
        }
        .category {
          width: 200px;
          text-align: center;
        }
        .xing {
          width: 200px;
          text-align: center;
        }
        .state {
          width: 100px;
          text-align: center;
        }
        .on {
          color: #53e60f;
        }
        .off {
          color: #e60f0f;
        }
        .time {
          width: 300px;
          text-align: center;
        }
      }
      .tool {
        width: 200px;
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .site-page {
    overflow-x: scroll;
    .head {
      .left {
        font-size: 16px;
        .site {
          width: 200px;
        }
        .xing {
          width: 150px;
        }
        .category {
          width: 100px;
        }

        .time {
          width: 150px;
        }
      }
      .tool {
        font-size: 16px;
      }
    }
    .body {
      .li {
        .left {
          font-size: 15px;
          .site {
            width: 200px;
          }
          .xing {
            width: 150px;
          }
          .category {
            width: 100px;
          }

          .time {
            width: 150px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .site-page {
    .toolbar{
      height: 80px;
      padding: 0 10px;
    }
    .head {
      height: 60px;
      padding: 0;
      .left {
        font-size: 13px;
        .site {
          width: 100px;
        }
        .xing {
          width: 80px;
        }
        .category {
          width: 100px;
        }
        .state {
          width: 50px;
        }
        .time {
          width: 100px;
        }
      }
      .tool {
        font-size: 13px;
      }
    }
    .body {
      padding: 0;
      .li {
        height: 60px;
        .left {
          font-size: 13px;
          .site {
            width: 100px;
          }
          .xing {
            width: 80px;
          }
          .category {
            width: 100px;
          }
          .state {
            width: 50px;
          }
          .time {
            width: 100px;
          }
        }
      }
    }
  }
}
